import React from 'react';
import _ from 'lodash';
import { Helmet } from 'react-helmet';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';

import Layout from '../../components/layout';
import Overview from '../../components/overview';

const baseRecipe = {
  spongeFlour: 500,
  spongeWater: 500,
  doughFlour: 400,
  doughYeast: 4.5,
  doughSalt: 20,
  doughMalt: 9.5
};

function scaleRecipe(numBagels) {
  const scaleFactor = numBagels / 12;
  return _.mapValues(baseRecipe, v => _.round(v * scaleFactor, 1));
}

const Form = styled.form`
  margin-top: 1rem;
`;

const ImgContainer = styled.div`
  width: 80%;
  margin: 0 auto 2rem auto;
`;

export default ({ data }) => {
  const [numBagels, setNumBagels] = React.useState(12);

  const recipe = scaleRecipe(numBagels);
  const levain = _.round((recipe.spongeWater + recipe.spongeFlour) * 0.2);

  return (
    <Layout>
      <Helmet>
        <title>Sourdough Bagels</title>
      </Helmet>
      <div>
        <h1>Sourdough Bagels</h1>
        <Img fluid={data.file.childImageSharp.fluid} />
        <Overview>
          This recipe seems long and complicated, but trust me, it only takes a
          few attempts to get it right. Since the sponge uses sourdough but the
          main dough uses instant yeast, you will get the flavor of sourdough,
          but the security of commercial yeast. This makes this recipe pretty
          forgiving to mistakes in fermentation temperatures and times.
        </Overview>
        <Form>
          <label className="label" htmlFor="numBagels">
            How many bagels would you like to make?
          </label>
          <div className="field">
            <div className="control">
              <input
                className="input"
                type="text"
                id="numBagels"
                name="numBagels"
                value={numBagels}
                onChange={e => setNumBagels(e.target.value)}
              />
            </div>
          </div>
        </Form>
        <h2>Sponge</h2>
        <table className="table is-bordered is-hoverable">
          <thead>
            <tr>
              <th>Ingredient</th>
              <th>Weight</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>High Protein Flour</td>
              <td>{recipe.spongeFlour} g</td>
            </tr>
            <tr>
              <td>Water</td>
              <td>{recipe.spongeWater} g</td>
            </tr>
            <tr>
              <td>Active Starter</td>
              <td>{levain} g</td>
            </tr>
          </tbody>
        </table>
        <h2>Dough</h2>
        <table className="table is-bordered is-hoverable">
          <thead>
            <tr>
              <th>Ingredient</th>
              <th>Weight</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>High Protein Flour</td>
              <td>{recipe.doughFlour} g</td>
            </tr>
            <tr>
              <td>Instant Yeast</td>
              <td>{recipe.doughYeast} g</td>
            </tr>
            <tr>
              <td>Salt</td>
              <td>{recipe.doughSalt} g</td>
            </tr>
            <tr>
              <td>
                <a href="https://www.amazon.com/Diastatic-Barley-Powder-Kosher-Certified/dp/B0721KWQFG/">
                  Diastatic Malt Powder
                </a>
              </td>
              <td>{recipe.doughMalt} g</td>
            </tr>
          </tbody>
        </table>
        <h2>Bagel Starch Coating</h2>
        <table className="table is-bordered is-hoverable">
          <thead>
            <tr>
              <th>Ingredient</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Water</td>
              <td>1/2 cup</td>
            </tr>
            <tr>
              <td>
                <a href="https://www.amazon.com/4mular-Ultratex-Tapioca-Modified-Additives/dp/B00GLQ41PW/">
                  Ultratex 3
                </a>
              </td>
              <td>1 Tbsp</td>
            </tr>
          </tbody>
        </table>
        <h2>Bagel Boiling Bath</h2>
        <table className="table is-bordered is-hoverable">
          <thead>
            <tr>
              <th>Ingredient</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Water</td>
              <td>Enough To Fill Pot</td>
            </tr>
            <tr>
              <td>Baking Soda</td>
              <td>1 Tbsp</td>
            </tr>
          </tbody>
        </table>
        <h1>Useful (but not necessary) Tools</h1>
        <ul>
          <li>Stand Mixer</li>
          <li>Bowl Scraper</li>
          <li>Bench Scraper</li>
          <li>Kitchen Towel</li>
          <li>Immersion Blender</li>
          <li>Scale (this is pretty necessary)</li>
        </ul>
        <h1>Ingredient Considerations</h1>
        <h2>Flour</h2>
        <p>
          Ideally you want to use the strongest, highest protein flour you can
          get. I usually make bagels with Central Milling High Mountain. Other
          good choices include King Arthur Sir Lancelot and General Mills All
          Trumps. If you can't get your hands on any of those either plain King
          Arthur Bread Flour (KABF) or KABF plus some additional wheat gluten
          should do the trick.
        </p>
        <h2>Water</h2>
        <p>
          While it is by no means necessary, I like to use dechlorinated water.
          If you live in a place that treats water with chlorine, simply letting
          it off-gas in an open container overnight will eliminate most of the
          chlorine. Filtration and boiling will also eliminate chlorine more
          rapidly. If your local water is treated with Chloramine, the
          aforementioned methods will not work, but it can be removed with
          Campden Tablets (potassium metabisulfite). Use caution when handling
          potassium metabisulfite and ensure that it is dosed properly. If you
          don't want to go through the hassle of dechlorinating your water, tap
          water works perfectly fine.
        </p>
        <h2>Malt Powder</h2>
        <p>If you cannot find diastatic malt powder, feel free to use honey.</p>
        <h2>Salt</h2>
        <p>Any non-iodized salt should work fine, no need to get too fancy.</p>
        <h1>Schedule</h1>
        <h2>Day 1</h2>
        <h3>
          <i>5:00 pm</i>
        </h3>
        <p>
          Begin by mixing together the ingredients to build up the active
          starter for the sponge. Use a 1:1 mixture of flour and water to end up
          with at least {levain} grams.
        </p>
        <ImgContainer>
          <Img fluid={data.step1.childImageSharp.fluid} />
        </ImgContainer>
        <h3>
          <i>9:00 pm - Midnight</i>
        </h3>
        <p>
          Allow the starter to ferment at room temperature until doubled in
          size. I use a rubber band around a jar to monitor its progress.
        </p>
        <ImgContainer>
          <Img fluid={data.step2.childImageSharp.fluid} />
        </ImgContainer>
        <p>
          Mix together the ingredients for the sponge until it forms a shaggy
          ball. If using a stand mixer this should take 1-2 minutes on low
          speed. Scrape down the sides of the bowl and cover with a damp towel
          to ferment overnight.
        </p>
        <ImgContainer>
          <Img fluid={data.step3.childImageSharp.fluid} />
        </ImgContainer>
        <h2>Day 2</h2>
        <h3>
          <i>9:00 am</i>
        </h3>
        <p>At this point the sponge should be very bubbly and light.</p>
        <ImgContainer>
          <Img fluid={data.step4.childImageSharp.fluid} />
        </ImgContainer>
        <p>
          Add the rest of the ingredients for the dough on top of the sponge.
        </p>
        <ImgContainer>
          <Img fluid={data.step5.childImageSharp.fluid} />
        </ImgContainer>
        <p>
          Mix and knead until a very well developed dough is formed. If using a
          stand mixed, 2 minutes on low, and 5 minutes on medium should be
          adequate. If kneading by hand, knead for at least 10 minutes.
        </p>
        <ImgContainer>
          <Img fluid={data.step6.childImageSharp.fluid} />
        </ImgContainer>
        <p>
          The dough should feel smooth and you should be able to pull a
          windowpane on it with no or minimal tearing.
        </p>
        <ImgContainer>
          <Img fluid={data.step7.childImageSharp.fluid} />
        </ImgContainer>
        <p>
          Immediately divide the dough into {numBagels} equal weight pieces.
        </p>
        <ImgContainer>
          <Img fluid={data.step8.childImageSharp.fluid} />
        </ImgContainer>
        <p>
          Form each piece into a rough ball. To round the each ball, place it on
          the counter and cup your hand behind the ball like so:
        </p>
        <ImgContainer>
          <Img fluid={data.step9.childImageSharp.fluid} />
        </ImgContainer>
        <p>
          Pull your hand towards you, using the tension of the dough against the
          counter to pull its surface tighter. Rotate the ball and repeat.
        </p>
        <ImgContainer>
          <Img fluid={data.step10.childImageSharp.fluid} />
        </ImgContainer>
        <p>
          Keep doing this until the ball is smooth and round. Repeat for all{' '}
          {numBagels} balls.
        </p>
        <ImgContainer>
          <Img fluid={data.step11.childImageSharp.fluid} />
        </ImgContainer>
        <p>
          Cover with a damp towel for <strong>20 minutes</strong>.
        </p>
        <ImgContainer>
          <Img fluid={data.step12.childImageSharp.fluid} />
        </ImgContainer>
        <h3>
          <i>9:20 am</i>
        </h3>
        <p>
          Now we are going to shape the bagels using hand rolling. Take one of
          the dough balls and pull on its sides slightly to make it into more of
          a rectangle shape.
        </p>
        <ImgContainer>
          <Img fluid={data.step13.childImageSharp.fluid} />
        </ImgContainer>
        <p>Continue pulling and pressing lightly until you have a log shape.</p>
        <ImgContainer>
          <Img fluid={data.step14.childImageSharp.fluid} />
        </ImgContainer>
        <p>
          Use both hands to roll out the log into roughly a six inch rope like
          so:
        </p>
        <ImgContainer>
          <Img fluid={data.step15.childImageSharp.fluid} />
        </ImgContainer>
        <ImgContainer>
          <Img fluid={data.step16.childImageSharp.fluid} />
        </ImgContainer>
        <p>
          Wrap the rope around the back of your hand so the end meet in your
          palm and overlap about <strong>2 inches</strong>:
        </p>
        <ImgContainer>
          <Img fluid={data.step17.childImageSharp.fluid} />
        </ImgContainer>
        <ImgContainer>
          <Img fluid={data.step18.childImageSharp.fluid} />
        </ImgContainer>
        <p>
          Press and roll using your palm against the seam of the bagel to seal
          it.
        </p>
        <ImgContainer>
          <Img fluid={data.step19.childImageSharp.fluid} />
        </ImgContainer>
        <p>Place the bagels on a tray with lightly oiled parchment paper.</p>
        <ImgContainer>
          <Img fluid={data.step20.childImageSharp.fluid} />
        </ImgContainer>
        <p>
          Brush the top of the bagels with oil and cover with plastic wrap.
          <strong>Ferment for 30 minutes in a warm spot</strong>.
        </p>
        <ImgContainer>
          <Img fluid={data.step21.childImageSharp.fluid} />
        </ImgContainer>
        <p>
          Place trays in the fridge and <strong>proof for 8-24 hours</strong>.
        </p>
        <h2>Day 3</h2>
        <h3>
          <i>9:00 am</i>
        </h3>
        <p>Preheat the oven to 500&deg; F.</p>
        <p>
          Mix the Ultratex 3 with the water. If you have an immersion blender,
          blend for 1-2 minutes. If not, whisk until well dissolved.
        </p>
        <ImgContainer>
          <Img fluid={data.step22.childImageSharp.fluid} />
        </ImgContainer>
        <p>Add baking soda to water in a large pot and bring to a boil.</p>
        <ImgContainer>
          <Img fluid={data.step23.childImageSharp.fluid} />
        </ImgContainer>
        <p>
          Place as many bagels as can comfortably fit, top side down, into the
          boiling water. <strong>Boil for 1 minute.</strong>
        </p>
        <ImgContainer>
          <Img fluid={data.step24.childImageSharp.fluid} />
        </ImgContainer>
        <p>
          Flip bagels over and <strong>boil for an additional 1 minute</strong>.
        </p>
        <ImgContainer>
          <Img fluid={data.step25.childImageSharp.fluid} />
        </ImgContainer>
        <p>
          Remove the bagels from the pot and place bottom down onto a parchment
          lined tray.{' '}
          <strong>
            Do not reuse the parchment paper from the proofing tray.
          </strong>
        </p>
        <ImgContainer>
          <Img fluid={data.step26.childImageSharp.fluid} />
        </ImgContainer>
        <h3>
          <i>9:30 am</i>
        </h3>
        <p>
          Bake the bagels for 5 minutes at 500&deg; F. Then rotate the tray
          180&deg; and bake for 15-20 more minutes at 450&deg; F or until golden
          brown.
        </p>
        <ImgContainer>
          <Img fluid={data.step27.childImageSharp.fluid} />
        </ImgContainer>
        <p>
          Remove the bagels from the oven. Let the bagels cool for at least 15
          minutes on a rack. Reduce the oven temperature to 350&deg; F while the
          bagels are cooling.
        </p>
        <p>Brush each bagel, one at a time, with the Ultratex 3 slurry.</p>
        <ImgContainer>
          <Img fluid={data.step28.childImageSharp.fluid} />
        </ImgContainer>
        <p>Quickly top the bagel with your desired toppings.</p>
        <ImgContainer>
          <Img fluid={data.step29.childImageSharp.fluid} />
        </ImgContainer>
        <p>
          Place bagels back into the 350&deg; F oven with the door cracked open
          for <strong>5-10 minutes</strong> to let the coating dry.
        </p>
        <p>Enjoy!</p>
        <ImgContainer>
          <Img fluid={data.step30.childImageSharp.fluid} />
        </ImgContainer>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    file(relativePath: { eq: "recipes/bagels/main.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 350, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step1: file(relativePath: { eq: "recipes/bagels/1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step2: file(relativePath: { eq: "recipes/bagels/2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step3: file(relativePath: { eq: "recipes/bagels/3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step4: file(relativePath: { eq: "recipes/bagels/4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step5: file(relativePath: { eq: "recipes/bagels/5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step6: file(relativePath: { eq: "recipes/bagels/6.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step7: file(relativePath: { eq: "recipes/bagels/7.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step8: file(relativePath: { eq: "recipes/bagels/8.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step9: file(relativePath: { eq: "recipes/bagels/9.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step10: file(relativePath: { eq: "recipes/bagels/10.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step11: file(relativePath: { eq: "recipes/bagels/11.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step12: file(relativePath: { eq: "recipes/bagels/12.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step13: file(relativePath: { eq: "recipes/bagels/13.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step14: file(relativePath: { eq: "recipes/bagels/14.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step15: file(relativePath: { eq: "recipes/bagels/15.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step16: file(relativePath: { eq: "recipes/bagels/16.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step17: file(relativePath: { eq: "recipes/bagels/17.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step18: file(relativePath: { eq: "recipes/bagels/18.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step19: file(relativePath: { eq: "recipes/bagels/19.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step20: file(relativePath: { eq: "recipes/bagels/20.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step21: file(relativePath: { eq: "recipes/bagels/21.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step22: file(relativePath: { eq: "recipes/bagels/22.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step23: file(relativePath: { eq: "recipes/bagels/23.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step24: file(relativePath: { eq: "recipes/bagels/24.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step25: file(relativePath: { eq: "recipes/bagels/25.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step26: file(relativePath: { eq: "recipes/bagels/26.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step27: file(relativePath: { eq: "recipes/bagels/27.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, cropFocus: SOUTH) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step28: file(relativePath: { eq: "recipes/bagels/28.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step29: file(relativePath: { eq: "recipes/bagels/29.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step30: file(relativePath: { eq: "recipes/bagels/30.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
